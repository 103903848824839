import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../layouts'

import people from "../../data/people.yaml"

import AboutSidenav from "../../partials/about-sidenav"

import '../../scss/about.scss'

const AboutBoardPage = ({data}) => {
  console.log(people)

  return (
    <Layout>
      <div className="about board">

      <Helmet>
        <title>DigiWells – The Board</title>
        <meta property="og:title" content="DigiWells – The Board" />
        <meta name="description" content="DigiWells board members." />
        <meta property="og:description" content="DigiWells board members." />
        {
          /*
          <meta name="image" content={image} />
          <meta property="og:image" content={image} />
          */
        }
      </Helmet>

        <div className="container">
          <AboutSidenav />

          <div className="about-content">
            <h1 className="page-title">The Board</h1>

            <div className="page-intro">
            </div>

            <div className="contact">
              <div className="people smaller">
                {
                  people.boardMembers &&
                  people.boardMembers.map(function(person) {
                    return (
                      <div className="person-card">
                        <div className="person-text">
                          <p className="person-name">{person.name}</p>
                          <p>{person.company}</p>
                          <p>{person.email}</p>
                          <p>{person.comment}</p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default AboutBoardPage
